import moment from "moment";

export class LTActionAreas {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "LTActionAreas";
    this.scene = "LT Action Areas";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];

    this.data.push({
      name: "Home Score",
      value: {
        text: options.game?.home_scorers?.length || 0,
      },
    });
    this.data.push({
      name: "Away Score",
      value: {
        text: options.game?.away_scorers?.length || 0,
      },
    });

    this.data.push({
      name: "Home Team Code",
      value: { text: options.game?.home_team?.code || "" },
    });

    this.data.push({
      name: "Home Team Name",
      value: { text: options.game?.home_team?.name || "" },
    });

    this.data.push({
      name: "Home Team Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Home Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Away Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Home Team Video Badge",
      value: {
        video: "${user}" + options.game?.home_team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Away Team Video Badge",
      value: {
        video: "${user}" + options.game?.away_team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Away Team Code",
      value: { text: options.game?.away_team?.code || "" },
    });

    this.data.push({
      name: "Away Team Name",
      value: { text: options.game?.away_team?.name || "" },
    });

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Competition Colour",
      value: { colour: options.game?.competition?.colour || "" },
    });
    this.data.push({
      name: "Stadium",
      value: {
        text:
          options.game?.stadium?.name || options.game?.stadium?.opta_name || "",
      },
    });
    this.data.push({
      name: "Kick-Off",
      value: {
        text: moment(options?.game?.time, "HH:mmZ")?.format("HH:mm") || "",
      },
    });

    this.data.push({
      name: "Period",
      value: { text: options.period.number },
    });
    let stat = this.buildStat({
      game: options.game,
      gameEvents: options.events,
      half: options.period.number,
    });

    this.data.push({
      name: "Pitch Left",
      value: { text: stat.left },
    });
    this.data.push({
      name: "Pitch Centre",
      value: { text: stat.centre },
    });
    this.data.push({
      name: "Pitch Right",
      value: { text: stat.right },
    });
    this.sendPreview(this.scene, 500, this.data, "IN");
  }

  buildStat({ game, gameEvents, half }) {
    let left, centre, right;
    if (
      gameEvents?.["kick_off_" + (half === 3 ? 1 : half)]?.[
        game?.home_team?.opta_ID
      ] === "Left to Right" ||
      gameEvents?.["kick_off_" + (half === 3 ? 1 : half)]?.[
        game?.away_team?.opta_ID
      ] === "Right to Left"
    ) {
      left =
        Math.round(
          ((gameEvents?.[game?.home_team?.opta_ID]?.defensive_third_home +
            gameEvents?.[game?.away_team?.opta_ID]?.attacking_third_away) /
            gameEvents?.action_areas_total) *
            100
        ) || 0;

      centre =
        Math.round(
          ((gameEvents?.[game?.home_team?.opta_ID]?.middle_third_home +
            gameEvents?.[game?.away_team?.opta_ID]?.middle_third_away) /
            gameEvents?.action_areas_total) *
            100
        ) || 0;

      right =
        100 -
        ((Math.round(
          ((gameEvents?.[game?.home_team?.opta_ID]?.defensive_third_home +
            gameEvents?.[game?.away_team?.opta_ID]?.attacking_third_away) /
            gameEvents?.action_areas_total) *
            100
        ) || 0) +
          (Math.round(
            ((gameEvents?.[game?.home_team?.opta_ID]?.middle_third_home +
              gameEvents?.[game?.away_team?.opta_ID]?.middle_third_away) /
              gameEvents?.action_areas_total) *
              100
          ) || 0));
    } else {
      left =
        Math.round(
          ((gameEvents?.[game?.home_team?.opta_ID]?.attacking_third_home +
            gameEvents?.[game?.away_team?.opta_ID]?.defensive_third_away) /
            gameEvents?.action_areas_total) *
            100
        ) || 0;

      centre =
        Math.round(
          ((gameEvents?.[game?.home_team?.opta_ID]?.middle_third_home +
            gameEvents?.[game?.away_team?.opta_ID]?.middle_third_away) /
            gameEvents?.action_areas_total) *
            100
        ) || 0;

      right =
        100 -
        ((Math.round(
          ((gameEvents?.[game?.home_team?.opta_ID]?.attacking_third_home +
            gameEvents?.[game?.away_team?.opta_ID]?.defensive_third_away) /
            gameEvents?.action_areas_total) *
            100
        ) || 0) +
          (Math.round(
            ((gameEvents?.[game?.home_team?.opta_ID]?.middle_third_home +
              gameEvents?.[game?.away_team?.opta_ID]?.middle_third_away) /
              gameEvents?.action_areas_total) *
              100
          ) || 0));
    }
    return { left, right, centre };
  }

  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
