import moment from "moment";

export class Fixtures {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Fixtures";
    this.scene = "Fixtures";
    this.data = [];
    this.page = 1;
  }

  preview({ options }) {
    if (!options.page) {
      this.page = 1;
    }
    this.options = options;
    this.onAir = false;

    this.data = [];

    this.data.push({
      name: "Page",
      value: { text: this.page },
    });
    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Competition Colour",
      value: { colour: options.game?.competition?.colour || "" },
    });
    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });
    options.fixtures = JSON.parse(JSON.stringify(options?.fixtures))?.sort(
      (a, b) => {
        return moment(a?.time, "HH:mmZ") - moment(b?.time, "HH:mmZ");
      }
    );
    options.fixtures = options?.fixtures?.sort((a, b) => {
      let a_date = moment(a.date + " " + a.time);
      let b_date = moment(b.date + " " + b.time);
      // Else go to the 2nd item
      if (a_date < b_date) {
        return -1;
      } else if (a_date > b_date) {
        return 1;
      } else {
        // nothing to split them
        if (a?.home_team?.name < b?.home_team?.name) {
          return -1;
        }
        if (a?.home_team?.name > b?.home_team?.name) {
          return 1;
        }
        return 0;
      }
    });
    new Array(36)?.fill(1)?.map((_, index) => {
      let game = options?.fixtures?.[index];

      let row = index + 1;

      this.data.push({
        name: "Game " + row + " Home Team Short Name",
        value: { text: game?.home_team?.short_name || "" },
      });
      this.data.push({
        name: "Game " + row + " Home Team Name",
        value: { text: game?.home_team?.name || "" },
      });
      this.data.push({
        name: "Game " + row + " Home Team Badge",
        value: { image: game?.home_team?.variables?.badge || "" },
      });

      this.data.push({
        name: "Game " + row + " Home Team Score",
        value: { text: game?.home_scorers?.length || "0" },
      });

      this.data.push({
        name: "Game " + row + " Away Team Name",
        value: { text: game?.away_team?.name || "" },
      });

      this.data.push({
        name: "Game " + row + " Away Team Short Name",
        value: { text: game?.away_team?.short_name || "" },
      });

      this.data.push({
        name: "Game " + row + " Away Team Badge",
        value: { image: game?.away_team?.variables?.badge || "" },
      });

      this.data.push({
        name: "Game " + row + " Away Team Score",
        value: { text: game?.away_scorers?.length || "0" },
      });

      this.data.push({
        name: "Game " + row + " Kick Off",
        value: {
          text: moment(game?.time, "HH:mmZ")?.format("HH:mm") || "",
        },
      });

      this.data.push({
        name: "Game " + row + " Date",
        value: { text: game?.date || "" },
      });

      let period = [...(game?.live_data?.periods || [])].sort(
        (a, b) => b.number - a.number
      )[0];

      this.data.push({
        name: "Game " + row + " Status",
        value: {
          text:
            game?.opta_status === "Played"
              ? "result"
              : game?.opta_status === "Postponed"
              ? "postponed"
              : period?.number === 2 && period?.kick_off === null
              ? "halftime"
              : period?.number > 1 || period?.kick_off !== null
              ? "started"
              : "notstarted",
        },
      });
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.page++;
    this.options.page = this.page;
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
    this.preview({ options: this.options });
  }
  animateOff({ options }) {
    this.page = 1;
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
