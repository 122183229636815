import React, { useContext } from "react";
import styled from "styled-components";
import { FaInfo } from "react-icons/fa";
import { UIContext } from "../../../contexts/UIContext";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  font-size: 0.7em;
`;
const InfoIcon = styled.div`
  margin-right: 2px;
  position: absolute;
  left: 3px;
  font-size: 1em;
`;
const Content = styled.div`
  display: flex;
  height: 100%;
  font-size: 1.2em;
  align-items: center;
  justify-content: center;
`;

export default function TeamTicker({
  item,
  previewGraphic,
  game,
  allOptaEvents,
}) {
  const { homeScorers, awayScorers } = useContext(UIContext);

  return (
    <Main
      onClick={() => {
        previewGraphic("TeamTicker", {
          game: game,
          team: game?.[item?.team + "_team"],
          squads: game.lineup?.[item?.team],
          start: true,
          events: allOptaEvents,
        });
      }}
    >
      <Title>
        <InfoIcon>GFX</InfoIcon>
        {game?.[item?.team + "_team"]?.short_name} Team Ticker
      </Title>
      <Content>PREVIEW</Content>
    </Main>
  );
}
