import React from "react";
import styled from "styled-components";
import Opta6 from "./OptaTypes.js/Opta6";
import Opta16 from "./OptaTypes.js/Opta16";
import Opta17 from "./OptaTypes.js/Opta17";
import Opta18 from "./OptaTypes.js/Opta18";
import Opta19 from "./OptaTypes.js/Opta19";
import Opta30 from "./OptaTypes.js/Opta30";
import Opta32 from "./OptaTypes.js/Opta32";
import Opta70 from "./OptaTypes.js/Opta70";
import Opta4 from "./OptaTypes.js/Opta4";
import Opta1 from "./OptaTypes.js/Opta1";
import Opta13 from "./OptaTypes.js/Opta13";
import Opta14 from "./OptaTypes.js/Opta14";
import Opta15 from "./OptaTypes.js/Opta15";
import Opta24 from "./OptaTypes.js/Opta24";
import Opta79 from "./OptaTypes.js/Opta79";
import LiveScore from "./OptaTypes.js/LiveScore";
import MatchFacts from "./GraphicTypes/MatchFacts";
import TeamTicker from "./GraphicTypes/TeamTicker";

const Main = styled.div`
  border-radius: 4px;
  background-color: #202731;
  height: 78%;
  min-width: 188px;
  margin: 0.2em;
  cursor: pointer;
  box-sizing: border-box;
  padding: 6px;
  display: flex;
  opacity: ${(props) => (props.faded ? "0.4" : "1")};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  :hover {
    background-color: #0adb93;
    color: #000;
  }
`;
const TeamColour = styled.div`
  height: 100%;
  width: 3px;
  border-radius: 4px;
  background-color: rgb(0, 255, 252);
`;
const Content = styled.div`
  padding: 0.2em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Footer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: auto;
`;
export default function GraphicEvent({
  graphicEvent,
  previewGraphic,
  index,
  game,
  allOptaEvents,
}) {
  return (
    <Main>
      <TeamColour
        style={{
          backgroundColor: "transparent",
        }}
      />

      <Content>
        {graphicEvent.graphic === "MatchFacts" && (
          <MatchFacts
            item={graphicEvent}
            previewGraphic={previewGraphic}
            game={game}
          />
        )}
        {graphicEvent.graphic === "TeamTicker" && (
          <TeamTicker
            item={graphicEvent}
            previewGraphic={previewGraphic}
            game={game}
            allOptaEvents={allOptaEvents}
          />
        )}
        {/* <Footer>
          <div>
            {graphicEvent.timeMin?.toString().padStart(2, "0") +
              ":" +
              graphicEvent.timeSec?.toString().padStart(2, "0")}
          </div>
        </Footer> */}
      </Content>
    </Main>
  );
}
