import moment from "moment";

export class TeamTicker {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "TeamTicker";
    this.scene = "Team Ticker";
    this.data = [];
    this.page = 1;
  }

   processSub({ lineup, events, team }) {
    let new_lineup = JSON.parse(JSON.stringify(lineup));
    //TODO make it work if sub comes on and then gets subbed off
    let sub_events = events?.filter((ev) => ev.typeId === 18);
    for (let i = 0; i < sub_events?.length; i++) {
      let off_ev = sub_events[i];

      let on_ev = events?.find((item) => {
        return (
          item.eventId ===
            parseInt(
              off_ev?.qualifier?.find((q) => q.qualifierId === 55)?.value
            ) && item.contestantId === team?.opta_ID
        );
      });
      
      let off_player = new_lineup?.find(
        (player) => off_ev?.playerId === player.squad?.player?.opta_ID
      );
      let on_player = new_lineup?.find(
        (player) => on_ev?.playerId === player.squad?.player?.opta_ID
      );
      if (on_player && off_player) {
        let off_player_order = off_player?.order;
        let on_player_order = on_player?.order;

        off_player.order = on_player_order;
        on_player.order = off_player_order;
        off_player.subbed = true;
        on_player.subbed = true;
      }
    }
    return new_lineup;
  }

  buildGoals({ events, team, player }) {
    
    let goals = {};
    if (events && events?.length > 0) {
      goals = 
        events.filter((item) => {
          let ownGoal = false;
          
          if (item.qualifier.find((q) => q.qualifierId === 28) !== undefined) {
            ownGoal = true;
          }
          let output = false;
          if (ownGoal && item.contestantId !== team?.opta_ID) {
            output = true;
          }
          if (ownGoal === false && item.contestantId === team?.opta_ID) {
            output = true;
          }
          if (item.playerId !== player.squad?.player?.opta_ID) {
            output=false;
          }
          return output;
        })
        
    let output = '';
    
      goals.map((item, index) => {
        if (index === 0) {
          output += " (";
        }

        if (item.periodId === 1 && item.timeMin >= 45) {
          output += "45'+" + parseInt(item.timeMin - 45 + 1);
        } else if (item.periodId === 2 && item.timeMin >= 90) {
          output += "90'+" + parseInt(item.timeMin - 90 + 1);
        } else {
          output += item.timeMin + 1;
          output += "'";
        }

        if (item.qualifier.find((q) => q.qualifierId === 9) !== undefined) {
          output += " P";
        }
        if (item.qualifier.find((q) => q.qualifierId === 28) !== undefined) {
          output += " OG";
        }
       if (goals.length > index+1){
          output += ",";
       }else{
        output += ")";
       }
        
      });
      
      return output;
    }
  }
processCards({events, player}){
  let cards = events?.filter((item) => {
    try {
      return item.typeId === 17 && item.playerId === player?.squad?.player?.opta_ID;
    } catch (e) {
      return false;
    }
  })
  if (cards?.find(
    (card) =>
   
      card.qualifier.find(
        (q) => q.qualifierId === 31
      ) !== undefined
  ) !== undefined) {
    return 'yellow'
  }
  if (cards?.find(
    (card) =>
    
      card.qualifier.find(
        (q) => q.qualifierId === 33 || q.qualifierId === 32
      ) !== undefined
  ) !== undefined) {
    return 'red'
  }
}


  preview({ options }) {
    if (options.start) {
      this.page = 1;
    }
    this.onAir = false;
    this.data = [];
    this.options = options;

    let team_type =
      options?.game?.home_team?._id === options.team?._id ? "home" : "away";

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Competition Colour",
      value: { colour: options.game?.competition?.colour || "" },
    });
    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    this.data.push({
      name: "Title",
      value: {
        text:
          options.game?.[team_type + "_formation"]?.rows?.substring(1) || "",
      },
    });

    let count = 0;
    for (let i = 0; i < this.page - 1; i++) {
      count += parseInt(options.game?.[team_type + "_formation"]?.rows?.[i]);
    }
    let lineup = options.squads;
    if (window.ENV?.REACT_APP_IMG || process.env.REACT_APP_IMG) {
     lineup = this.processSub({
      lineup: options.squads ,
      events: options.events,
      team: options.team,
    }) 
   
  }

    new Array(5).fill(1).forEach((_, index) => {
      let row = index + 1;
      let player = {};

      let number_in_row =
        options.game?.[team_type + "_formation"]?.rows?.[this.page - 1];
      console.log("number in row", number_in_row);
      if (number_in_row > index) {
        console.log("player index", count + index + 1);
        player = lineup?.find((s) => s.order === count + index + 1);
      }

      this.data.push({
        name: "First Name " + row,
        value: {
          text:
            player?.squad?.player?.first_name ??
            player?.squad?.player?.opta_first_name ??
            "",
        },
      });
      this.data.push({
        name: "Last Name " + row,
        value: {
          text:
            player?.squad?.player?.last_name ??
            player?.squad?.player?.opta_last_name ??
            "",
        },
      });
      this.data.push({
        name: "First Name Uppercase " + row,
        value: {
          text:
            player?.squad?.player?.first_name_upper ??
            player?.squad?.player?.opta_first_name?.toUpperCase() ??
            "",
        },
      });
      this.data.push({
        name: "Last Name Uppercase " + row,
        value: {
          text:
            player?.squad?.player?.last_name_upper ??
            player?.squad?.player?.opta_last_name?.toUpperCase() ??
            "",
        },
      });
      this.data.push({
        name: "Full Name " + row,
        value: {
          text:
            (player?.squad?.player?.first_name ??
              player?.squad?.player?.opta_first_name ??
              "") +
            " " +
            (player?.squad?.player?.last_name ??
              player?.squad?.player?.opta_last_name ??
              ""),
        },
      });
      this.data.push({
        name: "Full Name Uppercase" + row,
        value: {
          text:
            (player?.squad?.player?.first_name_upper ??
              player?.squad?.player?.opta_first_name?.toUpperCase() ??
              "") +
            " " +
            (player?.squad?.player?.last_name_upper ??
              player?.squad?.player?.opta_last_name?.toUpperCase() ??
              ""),
        },
      });
      this.data.push({
        name: "Captain " + row,
        value: {
          visible: player?.captain || false,
        },
      });
      this.data.push({
        name: "Position " + row,
        value: {
          text: player?.squad?.position || "",
        },
      });
      this.data.push({
        name: "Shirt Number " + row,
        value: {
          text:
            player?.squad?.shirt_number ??
            player?.squad?.opta_shirt_number ??
            "",
        },
      });
      
      this.data.push({
        name: "Subbed " + row,
        value: {
          text: player?.subbed,
        },
      });
    

      let goal_text = "";
      let goals = [
        ...(options.game?.home_scorers?.filter(
          (g) => g.player === player?.squad?._id
        ) || []),
        ...(options.game?.away_scorers?.filter(
          (g) => g.player === player?.squad?._id
        ) || []),
      ]?.sort((a, b) => {
        return a.time - b.time;
      });
      goals?.map((goal, index) => {
        let time = moment.duration(goal.time);
        let minutes = time.minutes() + time.hours() * 60;
        let goalTime = "";
        // minutes += 1;
        if (
          goal.period === 2 &&
          minutes > (options?.game?.config?.half_duration || 45) * 2
        ) {
          goalTime =
            (options?.game?.config?.half_duration || 45) * 2 +
            "+" +
            (minutes - (options?.game?.config?.half_duration || 45) * 2);
        } else if (
          goal.period === 1 &&
          minutes > (options?.game?.config?.half_duration || 45)
        ) {
          goalTime =
            (options?.game?.config?.half_duration || 45) +
            "+" +
            (minutes - (options?.game?.config?.half_duration || 45));
        } else {
          goalTime = minutes;
        }

        let subtitle = "";
        if (goal.own_goal) {
          subtitle = "OG";
        } else if (goal.penalty) {
          subtitle = "P";
        }
        if (index > 0) {
          goal_text += " ";
        }
        goal_text += goalTime + "'";
        if (subtitle !== "") {
          goal_text += " " + subtitle;
        }
      });
if (player) {
      goal_text = this.buildGoals({events: options.events?.filter((item) => {
        try {
          return item.typeId === 16;
        } catch (e) {
          return false;
        }
      }), team: options.team, player: player})
}
      this.data.push({
        name: "Goals " + row,
        value: {
          text: goal_text,
        },
      });
      if (window.ENV?.REACT_APP_IMG || process.env.REACT_APP_IMG) {
        
      let card = this.processCards({events:options.events, player})
      this.data.push({
        name: "Card " + row,
        value: {
          text:
          card ?? '',
        },
      });
      }else{
      this.data.push({
        name: "Card " + row,
        value: {
          text:
            player?.dbl_yellow_card || player?.red_card
              ? "red"
              : player?.yellow_card
              ? "yellow"
              : "",
        },
      });
    }
    });

    this.data.push({
      name: "Team Code",
      value: {
        text: options.team?.code,
      },
    });

    this.data.push({
      name: "Team Name",
      value: {
        text: options.team?.name,
      },
    });

    this.data.push({
      name: "Team Short Name",
      value: {
        text: options.team?.short_name,
      },
    });

    this.data.push({
      name: "Team Badge",
      value: {
        image: "${user}" + options.team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Team Secondary Badge",
      value: {
        image: "${user}" + options.team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Team Video Badge",
      value: {
        video: "${user}" + options.team?.variables?.video_badge,
      },
    });

    if (options?.game?.home_team?._id === options.team?._id) {
      this.data.push({
        name: "Team Kit BG Colour",
        value: {
          colour:
            options.team?.variables?.[
              options.game?.home_team_kit + "_kit_bg"
            ] || "",
        },
      });
      this.data.push({
        name: "Team Kit Text Colour",
        value: {
          colour:
            options.team?.variables?.[
              options.game?.home_team_kit + "_kit_text"
            ] || "",
        },
      });
    } else {
      this.data.push({
        name: "Team Kit BG Colour",
        value: {
          colour:
            options.team?.variables?.[
              options.game?.away_team_kit + "_kit_bg"
            ] || "",
        },
      });
      this.data.push({
        name: "Team Kit Text Colour",
        value: {
          colour:
            options.team?.variables?.[
              options.game?.away_team_kit + "_kit_text"
            ] || "",
        },
      });
    }

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    this.onAir_scene = this.scene;
    this.sendAnimate(this.scene, this.data);

    this.page++;
    this.options.start = false;
    this.preview({ options: this.options });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.onAir_scene, []);
    this.page = 1;
  }
}
