import moment from "moment";

export class LTZonesOfControl {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "LTZonesOfControl";
    this.scene = "LT Zones of Control";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];

    this.data.push({
      name: "Home Score",
      value: {
        text: options.game?.home_scorers?.length || 0,
      },
    });
    this.data.push({
      name: "Away Score",
      value: {
        text: options.game?.away_scorers?.length || 0,
      },
    });

    this.data.push({
      name: "Home Team Code",
      value: { text: options.game?.home_team?.code || "" },
    });

    this.data.push({
      name: "Home Team Name",
      value: { text: options.game?.home_team?.name || "" },
    });

    this.data.push({
      name: "Home Team Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Home Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Away Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Home Team Video Badge",
      value: {
        video: "${user}" + options.game?.home_team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Away Team Video Badge",
      value: {
        video: "${user}" + options.game?.away_team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Away Team Code",
      value: { text: options.game?.away_team?.code || "" },
    });

    this.data.push({
      name: "Away Team Name",
      value: { text: options.game?.away_team?.name || "" },
    });

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Competition Colour",
      value: { colour: options.game?.competition?.colour || "" },
    });
    this.data.push({
      name: "Stadium",
      value: {
        text:
          options.game?.stadium?.name || options.game?.stadium?.opta_name || "",
      },
    });
    this.data.push({
      name: "Kick-Off",
      value: {
        text: moment(options?.game?.time, "HH:mmZ")?.format("HH:mm") || "",
      },
    });

    this.data.push({
      name: "Period",
      value: { text: options.period.number },
    });
    let i = 1;
    for (let row = 1; row <= 5; row++) {
      for (let col = 1; col <= 6; col++) {
        let stat = this.zone({
          stats: options.events,
          game: options.game,
          row,
          column: col,
          kick_off: options.events?.["kick_off_" + options.period.number],
        });
        this.data.push({
          name: "Zone Value " + i,
          value: {
            text: stat.percent ? (parseInt(stat.percent) ?? "0") + "%" : "",
            colour: stat.kit_text ? stat.kit_text : "#000000",
          },
        });
        this.data.push({
          name: "Zone Colour " + i,
          value: {
            colour: stat.kit_bg ?? "",
            alpha: !stat.kit_bg ? 0 : 1,
          },
        });
        this.data.push({
          name: "Zone Team " + i,
          value: {
            colour: stat.team?.code,
          },
        });
        i++;
      }
    }
    this.sendPreview(this.scene, 500, this.data, "IN");
  }

  zone({ stats, game, row, column, kick_off, view }) {
    let left_kit_text =
      kick_off?.[game?.home_team?.opta_ID] === "Left to Right" ||
      kick_off?.[game?.away_team?.opta_ID] === "Right to Left"
        ? game?.home_team?.variables?.[game?.home_team_kit + "_kit_text"]
        : game?.away_team?.variables?.[game?.away_team_kit + "_kit_text"];
    let left_kit_bg =
      kick_off?.[game?.home_team?.opta_ID] === "Left to Right" ||
      kick_off?.[game?.away_team?.opta_ID] === "Right to Left"
        ? game?.home_team?.variables?.[game?.home_team_kit + "_kit_bg"]
        : game?.away_team?.variables?.[game?.away_team_kit + "_kit_bg"];
    let left_team =
      kick_off?.[game?.home_team?.opta_ID] === "Left to Right" ||
      kick_off?.[game?.away_team?.opta_ID] === "Right to Left"
        ? game?.home_team
        : game?.away_team;
    let right_kit_text =
      kick_off?.[game?.home_team?.opta_ID] === "Left to Right" ||
      kick_off?.[game?.away_team?.opta_ID] === "Right to Left"
        ? game?.away_team?.variables?.[game?.away_team_kit + "_kit_text"]
        : game?.home_team?.variables?.[game?.home_team_kit + "_kit_text"];
    let right_kit_bg =
      kick_off?.[game?.home_team?.opta_ID] === "Left to Right" ||
      kick_off?.[game?.away_team?.opta_ID] === "Right to Left"
        ? game?.away_team?.variables?.[game?.away_team_kit + "_kit_bg"]
        : game?.home_team?.variables?.[game?.home_team_kit + "_kit_bg"];
    let right_team =
      kick_off?.[game?.home_team?.opta_ID] === "Left to Right" ||
      kick_off?.[game?.away_team?.opta_ID] === "Right to Left"
        ? game?.away_team
        : game?.home_team;
    let left_value =
      stats?.[left_team?.opta_ID]?.zone_touches?.[column + ":" + row];
    let right_value =
      stats?.[right_team?.opta_ID]?.zone_touches?.[
        7 - column + ":" + (6 - row)
      ];
    let percent = (left_value / (left_value + right_value)) * 100;

    return {
      percent,
      team: percent > 55 ? left_team : percent < 45 ? right_team : null,
      kit_text:
        percent > 55 ? left_kit_text : percent < 45 ? right_kit_text : null,
      kit_bg: percent > 55 ? left_kit_bg : percent < 45 ? right_kit_bg : null,
    };
  }

  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
