import React, { useEffect, useState } from "react";
import styled from "styled-components";
import OptaEvent from "./OptaEvent";
import { ScrollBar } from "../../Theme/Hyper";
import GraphicEvent from "./GraphicEvent";
import moment from "moment";

const Main = styled.div`
  height: 100%;
  display: flex;
  height: 16vh;
  max-height: 170px;
  position: fixed;
  bottom: 0px;
`;
const ActionPanel = styled(ScrollBar)`
  display: flex;
  width: calc(100vw - 375px);
  margin-bottom: 1em;

  /* padding: 1em; */
  align-items: center;
  box-sizing: border-box;

  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  overflow-x: auto;
  padding: 0.5em;
`;
const OPTA_PERIODS = [
  { value: 16, name: "Pre-match" },
  { value: 1, name: "First half" },
  { value: 10, name: "Half time" },
  { value: 2, name: "Second half" },
  { value: 3, name: "Extra time first half" },
  { value: 4, name: "Extra time second half" },
  { value: 5, name: "Penalty shootout" },
  { value: 11, name: "End of second half (before extra time)" },
  { value: 12, name: "Extra time half time" },
  { value: 13, name: "End of extra time (before penalties)" },
  { value: 14, name: "Full time" },
];

export default function Timeline({
  matchEvents,
  previewGraphic,
  game,
  teams,
  allOptaEvents,
}) {
  const [graphics, setGraphics] = useState([
    {
      type: "graphic",
      graphic: "MatchFacts",
      timeMin: 15,
      timeSec: 0,
      period: 1,
    },
    {
      type: "graphic",
      graphic: "MatchFacts",
      timeMin: 30,
      timeSec: 0,
      period: 1,
    },
    {
      type: "graphic",
      graphic: "MatchFacts",
      timeMin: 60,
      timeSec: 0,
      period: 2,
    },
    {
      type: "graphic",
      graphic: "MatchFacts",
      timeMin: 75,
      timeSec: 0,
      period: 2,
    },
    // {
    //   type: "graphic",
    //   graphic: "TeamTicker",
    //   timeMin: 45,
    //   timeSec: 0,
    //   period: 2,
    //   team: "home",
    // },
    // {
    //   type: "graphic",
    //   graphic: "TeamTicker",
    //   timeMin: 45,
    //   timeSec: 0,
    //   period: 2,
    //   team: "away",
    // },
    // {
    //   type: "graphic",
    //   graphic: "ActionAreas",
    //   timeMin: 40,
    //   timeSec: 0,
    //   period: 1,
    // },
    // {
    //   type: "graphic",
    //   graphic: "ActionAreas",
    //   timeMin: 80,
    //   timeSec: 0,
    //   period: 2,
    // },
  ]);
  const [mins, setMins] = useState(0);
  const [period, setPeriod] = useState(0);
  let newMatchEvents = matchEvents;

  useEffect(() => {
    let interval = setInterval(() => {
      let mins = document.getElementById("hyper-clock-mins")?.innerText;
      setMins(mins);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let periods = [...(game.live_data?.periods || [])];
    let period = periods.sort((a, b) => b.number - a.number)[0];

    setPeriod(period);
  }, [game]);

  return (
    <Main>
      <ActionPanel
        onWheel={(e) => {
          if (e.deltaY > 0) e.currentTarget.scrollLeft += 100;
          else e.currentTarget.scrollLeft -= 100;
        }}
      >
        {graphics
          ?.filter((item) => {
            return (
              item.timeMin === parseInt(mins) &&
              period?.number === item.period &&
              period.kick_off
            );
          })
          ?.map((item, index) => {
            return (
              <GraphicEvent
                index={index}
                previewGraphic={previewGraphic}
                game={game}
                graphicEvent={item}
                allOptaEvents={allOptaEvents}
              />
            );
          })}

        {newMatchEvents &&
          newMatchEvents
            ?.toReversed()
            ?.filter((e) => e.periodId !== 5)
            ?.filter(
              (e) =>
                e?.typeId !== "live_score" ||
                (e?.typeId === "live_score" &&
                  e?.game_ID?._id !== game?._id &&
                  e?.game_ID?.competition?._id !== game?.competition?._id)
            )
            .map((item, index) => {
              return (
                <OptaEvent
                  index={index}
                  total={newMatchEvents.length}
                  matchEvent={item}
                  previewGraphic={previewGraphic}
                  game={game}
                  teams={teams}
                  events={newMatchEvents}
                />
              );
            })}
      </ActionPanel>
    </Main>
  );
}
