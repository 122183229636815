import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";

const MonitorContext = React.createContext([{}, () => {}]);

window.monitor_ws = null;

const MonitorProvider = (props) => {
  const [ws, setWS] = useState(null);
  const [wsStatus, setWSStatus] = useState(-1);
  const [game, setGame] = useState();
  const [page, setPage] = useState();
  const sendData = (data) => {
    try {
      if (window.monitor_ws) {
        data.user = {
          id: window.id,
        };

        data.group = localStorage.getItem("user-id");
        data.app = "football";

        data.engine =
          window.engines?.find((e) => e.key === window.selectedEngine)?.key ||
          window.engines?.[0]?.key;
        window.monitor_ws.send(
          JSON.stringify({
            ...data,
            version: window.version,
            time: moment(),
          })
        );
      }
    } catch (err) {}
  };

  useEffect(() => {
    connect();
  }, []);

  function connect() {
    console.log("Hyper monitor connecting to server");

    window.monitor_ws = new WebSocket(window.monitor_server);
    setWS(window.monitor_ws);
  }

  useEffect(() => {
    if (window.monitor_ws) {
      let timeout;
      window.monitor_ws.onopen = () => {
        setWSStatus(1);
        console.log("Hyper monitor on open");
        keep_alive();
      };
      window.monitor_ws.onmessage = (data) => {
        console.log("Hyper monitor on message");
      };
      window.monitor_ws.onerror = (err) => {
        console.log("Hyper monitor on error");
        ws.close();
      };
      window.monitor_ws.onclose = (data) => {
        setWSStatus(0);
        console.log("Hyper monitor on close");
        clearTimeout(timeout);
        clearInterval(window.monitor_keep_alive);
        timeout = setTimeout(() => {
          connect();
        }, 1000);
      };
    }
  }, [ws]);

  const keep_alive = useCallback(() => {
    try {
      window.monitor_ws.send(
        JSON.stringify({
          app: "football",
          monitor_keep_alive: Date.now(),
          group: window.id,
          game: game?.description,
          version: window.version,
          time: moment(),
          page,
          machine_name: window.localStorage.getItem("machine_name"),
          has_home_lineups: game?.lineup?.home?.length > 0,
          has_away_lineups: game?.lineup?.away?.length > 0,
        })
      );
    } catch (err) {
      console.log(err);
    }
  }, [game, page]);

  useEffect(() => {
    clearInterval(window.monitor_keep_alive);
    window.monitor_keep_alive = setInterval(() => {
      keep_alive();
    }, 20000);
    keep_alive();
  }, [keep_alive]);

  return (
    <MonitorContext.Provider
      value={{
        sendData,
        setGame,
        setPage,
      }}
    >
      {props.children}
    </MonitorContext.Provider>
  );
};

export { MonitorContext, MonitorProvider };
