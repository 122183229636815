import React, { useState, useEffect, useContext } from "react";

const event_types = {
  1: "Pass",
  2: "Offside Pass",
  3: "Attempted Dribble",
  4: "Foul",
  5: "Ball Goes Out",
  6: "Corner Given",
  7: "Tackle",
  8: "Interception",
  10: "Save",
  11: "GK Claim",
  12: "Clearance",
  13: "Shot Missed",
  14: "Post Hit",
  15: "Shot Saved",
  16: "Goal",
  17: "Card Shown",
  18: "Subbed Off",
  19: "Subbed On",
  20: "Player Off Injured",
  21: "Player Back On",
  22: "Outfielder In Goal",
  23: "GK Plays Outfield",
  24: "Condition Change",
  25: "Official Change",
  27: "Start of Delay",
  28: "Delay Ends",
  30: "End of Period",
  32: "Start of Period",
  34: "Team Formation",
  35: "Player Position Change",
  36: "Shirt no. Change",
  37: "Opta Data Ends",
  38: "Temp Goal Event",
  39: "Temp Shot Event",
  40: "Formation Change",
  41: "GK Punch",
  42: "Good Skill",
  43: "Deleted",
  44: "Aerial Duel",
  45: "Dribbled Past",
  49: "Ball Recovered",
  50: "Dispossessed",
  51: "Mistake",
  52: "GK Picks up Ball",
  53: "Cross Missed",
  54: "GK Smothers",
  55: "Last Defender at Offside",
  56: "Shield Out of Play",
  57: "Foul Throw",
  58: "GK Faces Penalty",
  59: "Keeper Sweeper",
  61: "Generic touch",
  67: "50/50",
  74: "Blocked pass",
  83: "Attempted tackle",
};
const worker1 = new Worker("/EventProcessorWorker.js");
const worker2 = new Worker("/EventProcessorWorker.js");
const worker3 = new Worker("/EventProcessorWorker.js");
const worker4 = new Worker("/EventProcessorWorker.js");
const worker5 = new Worker("/EventProcessorWorker.js");
let last_updated = "";
const GameEventsContext = React.createContext([{}, () => {}]);

const GameEventsProvider = (props) => {
  const [events, setEvents] = useState([]);
  const [game, setGame] = useState();
  const [processedTotalEvent, setProcessedTotalEvents] = useState({});
  const [processedFirstEvent, setProcessedFirstEvents] = useState({});
  const [processedSecondEvent, setProcessedSecondEvents] = useState({});
  const [processedAETFirstEvent, setProcessedAETFirstEvents] = useState({});
  const [processedAETSecondEvent, setProcessedAETSecondEvents] = useState({});

  useEffect(() => {
    // Note: in your actual app code, make sure to check if Home
    // is still mounted before setting state asynchronously!
    if (events?.length > 0 && game) {
      worker1.postMessage({
        events: events || [],
        game,
      });

      worker1.onmessage = (e) => {
        setProcessedTotalEvents(e.data);
      };
    }
  }, [events, game]);

  useEffect(() => {
    // Note: in your actual app code, make sure to check if Home
    // is still mounted before setting state asynchronously!
    if (events?.length > 0 && game) {
      let filter_events = [];
      filter_events = events?.filter((e) => e.periodId === 1) || [];

      worker2.postMessage({
        events: filter_events || [],
        game,
      });

      worker2.onmessage = (e) => {
        setProcessedFirstEvents(e.data);
      };
    }
  }, [events, game]);

  useEffect(() => {
    // Note: in your actual app code, make sure to check if Home
    // is still mounted before setting state asynchronously!
    if (events?.length > 0 && game) {
      let filter_events = [];
      filter_events = events?.filter((e) => e.periodId === 2) || [];

      worker3.postMessage({
        events: filter_events || [],
        game,
      });

      worker3.onmessage = (e) => {
        setProcessedSecondEvents(e.data);
      };
    }
  }, [events, game]);

  useEffect(() => {
    // Note: in your actual app code, make sure to check if Home
    // is still mounted before setting state asynchronously!
    if (events?.length > 0 && game) {
      let filter_events = [];
      filter_events = events?.filter((e) => e.periodId === 3) || [];

      worker4.postMessage({
        events: filter_events || [],
        game,
      });

      worker4.onmessage = (e) => {
        setProcessedAETFirstEvents(e.data);
      };
    }
  }, [events, game]);

  useEffect(() => {
    // Note: in your actual app code, make sure to check if Home
    // is still mounted before setting state asynchronously!
    if (events?.length > 0 && game) {
      let filter_events = [];
      filter_events = events?.filter((e) => e.periodId === 4) || [];

      worker5.postMessage({
        events: filter_events || [],
        game,
      });

      worker5.onmessage = (e) => {
        setProcessedAETSecondEvents(e.data);
      };
    }
  }, [events, game]);

  return (
    <GameEventsContext.Provider
      value={{
        events,
        processedTotalEvent,
        processedFirstEvent,
        processedSecondEvent,
        processedAETFirstEvent,
        processedAETSecondEvent,
        setEvents,
        setGame,
      }}
    >
      {props.children}
    </GameEventsContext.Provider>
  );
};
export { GameEventsContext, GameEventsProvider };
