import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  split,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Startup from "./Startup";
import { getMainDefinition } from "@apollo/client/utilities";
import { UserProvider } from "./contexts/UserContext";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { RetryLink } from "@apollo/client/link/retry";
import { createClient } from "graphql-ws";
import { GameEventsProvider } from "./contexts/GameEventContext";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

let wsURI =
  window.ENV?.REACT_APP_FOOTBALL_API || process.env.REACT_APP_FOOTBALL_API;
wsURI = wsURI.replace("http", "ws");
wsURI = wsURI.replace("https", "wss");

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${wsURI}/graphql`,
    shouldRetry: () => {
      return true;
    },
    retryAttempts: Infinity,
    options: {
      reconnect: true,
      retryAttempts: 500,
    },
  })
);

const httpLink = createHttpLink({
  uri:
    (window.ENV?.REACT_APP_FOOTBALL_API || process.env.REACT_APP_FOOTBALL_API) +
    "/graphql",
});

const retryLink = new RetryLink({
  delay: {
    initial: 1000,
    max: 5000,
    jitter: true,
  },
  attempts: {
    max: 5,
  },
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([retryLink, link]),
});

window.version = window.ENV?.REACT_APP_VERSION || process.env.REACT_APP_VERSION;
document.title = document.title + " | " + window.version;

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <UserProvider>
        <GameEventsProvider>
          <Startup />
        </GameEventsProvider>
      </UserProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
