import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import EFLLineup from "../Lineup/EFLLineup";
import { DataContext } from "../../contexts/DataContext";
import { ControlContext } from "../../contexts/ControlContext";
import { APIContext } from "../../contexts/APIContext";
import TeamStats from "../TeamStats/TeamStats";
import Timeline from "../Timeline/Timeline";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { UIContext } from "../../contexts/UIContext";
import KitSelection from "../KitSelection/KitSelection";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  display: flex;
  border-bottom: 1px solid #dadde1;
  box-sizing: border-box;
  font-size: 1.5em;
  width: 100%;
  padding: 0.5em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ActionPanel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  /* padding: 1em; */
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  height: 100%;
`;

const Lineups = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  height: 70vh;
`;

const Centre = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  align-items: center;
`;

const SubsConfirm = styled.div`
  display: flex;
`;

const TeamSubs = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
`;
const Button = styled.div`
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  display: flex;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "rgb(32, 39, 49)"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  /* width: 100%; */
  justify-content: center;
  margin-top: 0.5em;
  padding: 0.5em;
  width: 80%;
  min-height: 35px;
  :hover {
    background-color: #db0a41;
  }
`;

const HalfButton = styled(Button)`
  width: 45%;
  margin: 5px;
`;

const Row = styled.div`
  display: flex;
  width: 90%;
`;
export default function EFLGame({
  game,
  setSelectedPlayer,
  selectedPlayer,
  setSelectedSubPlayer,
  selectedSubPlayer,
  goal,
  setGoal,
  optaLineup,
  countries,
  sky,
  setHomeManager,
  setAwayManager,
  optaEvents,
  liveScores,
  allOptaEvents,
}) {
  const { update } = useContext(DataContext);
  const { clear, prev } = useContext(ControlContext);
  const { updateGame } = useContext(APIContext);
  const { selectedTeam } = useContext(UIContext);
  const [group, setGroup] = useState();
  const { previewGraphic, updateGraphic } = useContext(GraphicsContext);

  function processSub({ lineup, events, team }) {
    let new_lineup = JSON.parse(JSON.stringify(lineup));
    //TODO make it work if sub comes on and then gets subbed off
    let sub_events = events?.filter((ev) => ev.typeId === 18);
    for (let i = 0; i < sub_events?.length; i++) {
      let off_ev = sub_events[i];

      let on_ev = events?.find((item) => {
        return (
          item.eventId ===
            parseInt(
              off_ev?.qualifier?.find((q) => q.qualifierId === 55)?.value
            ) && item.contestantId === team?.opta_ID
        );
      });
      let off_player = new_lineup?.find(
        (player) => off_ev?.playerId === player.squad?.player?.opta_ID
      );
      let on_player = new_lineup?.find(
        (player) => on_ev?.playerId === player.squad?.player?.opta_ID
      );
      if (on_player && off_player) {
        let off_player_order = off_player?.order;
        let on_player_order = on_player?.order;

        off_player.order = on_player_order;
        on_player.order = off_player_order;
      }
    }
    return new_lineup;
  }

  return (
    <Main>
      {game && game.home_team && game.away_team && (
        <Content>
          <ActionPanel>
            <Lineups>
              {game && (
                <EFLLineup
                  setTeamManager={setHomeManager}
                  countries={countries}
                  optaLineup={optaLineup}
                  game={game}
                  kickedOff={true}
                  kickOffTime={game.actual_kick_off}
                  team={game.home_team}
                  lineup={
                    processSub({
                      lineup: game?.lineup?.home,
                      events: optaEvents,
                      team: game?.home_team,
                    }) || game.opta_lineup?.home
                  }
                  scorers={[
                    ...game?.home_scorers,
                    ...game?.away_scorers?.filter((g) => g.own_goal),
                  ]}
                  selectedPlayer={selectedPlayer}
                  selectPlayer={(player) => {
                    if (goal) {
                      //setSelectedPlayer({ ...player, team: game.home_team });
                      let own_goal = goal.team._id !== game.home_team._id;
                      let newScorers = [
                        ...(own_goal ? game.away_scorers : game.home_scorers),
                      ];
                      let index = newScorers.findIndex(
                        (g) => g._id === goal._id
                      );
                      if (index > -1) {
                        setGoal();
                        let periods = [...(game.live_data?.periods || [])];
                        let period = periods.sort(
                          (a, b) => b.number - a.number
                        )[0];

                        newScorers[index] = {
                          ...newScorers[index],
                          player: player.squad._id,
                          time: goal.time,
                          own_goal: goal.team._id !== game.home_team._id,
                          penalty: goal.penalty,
                          period: period.number,
                        };

                        if (own_goal) {
                          updateGame({
                            id: game._id,
                            away_scorers: newScorers,
                          });
                        } else {
                          updateGame({
                            id: game._id,
                            home_scorers: newScorers,
                          });
                        }
                      }
                    } else {
                      if (player) {
                        setSelectedPlayer({ ...player, team: game.home_team });
                      } else {
                        setSelectedPlayer();
                      }
                    }
                  }}
                  selectedSubPlayer={selectedSubPlayer}
                  selectSubPlayer={(player) => {
                    if (player) {
                      setSelectedSubPlayer({ ...player, team: game.away_team });
                    } else {
                      setSelectedSubPlayer();
                    }
                  }}
                  removeGoal={(goal) => {
                    if (!goal.own_goal) {
                      updateGame({
                        id: game._id,
                        home_scorers: game.home_scorers.filter(
                          (s) =>
                            s.player !== goal.player || s.time !== goal.time
                        ),
                      });
                    } else {
                      updateGame({
                        id: game._id,
                        away_scorers: game.away_scorers.filter(
                          (s) =>
                            s.player !== goal.player || s.time !== goal.time
                        ),
                      });
                    }
                  }}
                  cards={
                    optaEvents?.filter((item) => {
                      try {
                        return item.typeId === 17;
                      } catch (e) {
                        return false;
                      }
                    }) || []
                  }
                  subs={
                    optaEvents?.filter((item) => {
                      try {
                        return item.typeId === 18 || item.typeId === 19;
                      } catch (e) {
                        return false;
                      }
                    }) || []
                  }
                  goals={(
                    optaEvents?.filter((item) => {
                      try {
                        return item.typeId === 16 && item?.periodId !== 5;
                      } catch (e) {
                        return false;
                      }
                    }) || []
                  )
                    .concat(game?.home_scorers)
                    .concat(game?.away_scorers)}
                />
              )}
              <Centre>
                <Button
                  red
                  onClick={() => {
                    clear();
                  }}
                >
                  CLEAR ALL GFX <br />
                  (Ctl+Shift+Q)
                </Button>

                {game?.opta_team_stats?.home && <TeamStats game={game} />}
                <Button
                  onClick={() => {
                    previewGraphic("FFSponsor", {
                      game,
                    });
                  }}
                >
                  Partners
                </Button>
                <Button
                  onClick={() => {
                    debugger;
                    let official = game?.officials?.find(
                      (o) => o?.type === "Main"
                    );
                    previewGraphic("Referee", {
                      player: official?.official,
                      subtext: official.type
                        .replace("Main", "Referee")
                        .replace("Lineman 1", "Assistant Referee")
                        .replace("Lineman 2", "Assistant Referee"),
                      game,
                    });
                  }}
                >
                  Referee (
                  {
                    game?.officials?.find((o) => o?.type === "Main")?.official
                      ?.first_name
                  }{" "}
                  {
                    game?.officials?.find((o) => o?.type === "Main")?.official
                      ?.last_name
                  }
                  )
                </Button>
                {[...(game.live_data?.periods || [])]?.sort(
                  (a, b) => b.number - a.number
                )[0]?.number === 1 &&
                  [...(game.live_data?.periods || [])]?.sort(
                    (a, b) => b.number - a.number
                  )[0]?.kick_off === null && (
                    <>
                      <Button
                        onClick={() => {
                          previewGraphic("TeamArrival", {
                            player: {
                              first_name_upper: "",
                              last_name_upper: game?.home_team?.name,
                            },
                            subtext: "team arrival",
                            team: game?.home_team,
                            game,
                          });
                        }}
                      >
                        Home Team Arrival
                      </Button>
                      <Button
                        onClick={() => {
                          previewGraphic("TeamArrival", {
                            player: {
                              first_name_upper: "",
                              last_name_upper: game?.away_team?.name,
                            },
                            subtext: "team arrival",
                            team: game?.away_team,
                            game,
                          });
                        }}
                      >
                        Away Team Arrival
                      </Button>
                    </>
                  )}
                {/* {([...(game.live_data?.periods || [])]?.sort(
                  (a, b) => b.number - a.number
                )[0]?.number > 1 ||
                  [...(game.live_data?.periods || [])]?.sort(
                    (a, b) => b.number - a.number
                  )[0]?.kick_off !== null) && (
                  <>
                    Avg. Positions
                    <Row>
                      <HalfButton
                        onClick={() => {
                          previewGraphic("TouchMap", {
                            team: game?.home_team,
                            optaEvents,
                            game,
                          });
                        }}
                      >
                        Home Team
                      </HalfButton>
                      <HalfButton
                        onClick={() => {
                          previewGraphic("TouchMap", {
                            team: game?.away_team,
                            optaEvents,
                            game,
                          });
                        }}
                      >
                        Away Team
                      </HalfButton>
                    </Row>
                  </>
                )} */}
                {game?.commentators && (
                  <Button
                    onClick={() => {
                      previewGraphic("Commentators", {
                        commentators: game?.commentators,
                        game,
                      });
                    }}
                  >
                    Commentators
                  </Button>
                )}
              </Centre>
              {game && (
                <EFLLineup
                  setTeamManager={setAwayManager}
                  countries={countries}
                  optaLineup={optaLineup}
                  game={game}
                  kickedOff={true}
                  kickOffTime={game.actual_kick_off}
                  team={game.away_team}
                  scorers={[
                    ...game?.away_scorers,
                    ...game?.home_scorers?.filter((g) => g.own_goal),
                  ]}
                  lineup={
                    processSub({
                      lineup: game?.lineup?.away,
                      events: optaEvents,
                      team: game?.away_team,
                    }) || game.opta_lineup?.away
                  }
                  selectedPlayer={selectedPlayer}
                  selectPlayer={(player) => {
                    if (goal) {
                      //setSelectedPlayer({ ...player, team: game.away_team });

                      let own_goal = goal.team._id !== game.away_team._id;
                      let newScorers = [
                        ...(own_goal ? game.home_scorers : game.away_scorers),
                      ];
                      let index = newScorers.findIndex(
                        (g) => g._id === goal._id
                      );
                      if (index > -1) {
                        setGoal();
                        let periods = [...(game.live_data?.periods || [])];
                        let period = periods.sort(
                          (a, b) => b.number - a.number
                        )[0];

                        newScorers[index] = {
                          ...newScorers[index],
                          player: player.squad._id,
                          time: goal.time,
                          own_goal: goal.team._id !== game.away_team._id,
                          penalty: goal.penalty,
                          period: period.number,
                        };

                        if (own_goal) {
                          updateGame({
                            id: game._id,
                            home_scorers: newScorers,
                          });
                        } else {
                          updateGame({
                            id: game._id,
                            away_scorers: newScorers,
                          });
                        }
                      }
                    } else {
                      if (player) {
                        setSelectedPlayer({ ...player, team: game.away_team });
                      } else {
                        setSelectedPlayer();
                      }
                    }
                  }}
                  selectedSubPlayer={selectedSubPlayer}
                  selectSubPlayer={(player) => {
                    if (player) {
                      setSelectedSubPlayer({ ...player, team: game.away_team });
                    } else {
                      setSelectedSubPlayer();
                    }
                  }}
                  removeGoal={(goal) => {
                    if (goal.own_goal === false) {
                      updateGame({
                        id: game._id,
                        away_scorers: game.away_scorers.filter(
                          (s) =>
                            s.player !== goal.player || s.time !== goal.time
                        ),
                      });
                    } else {
                      updateGame({
                        id: game._id,
                        home_scorers: game.home_scorers.filter(
                          (s) =>
                            s.player !== goal.player || s.time !== goal.time
                        ),
                      });
                    }
                  }}
                  cards={
                    optaEvents?.filter((item) => {
                      try {
                        return item.typeId === 17;
                      } catch (e) {
                        return false;
                      }
                    }) || []
                  }
                  subs={
                    optaEvents?.filter((item) => {
                      try {
                        return item.typeId === 18 || item.typeId === 19;
                      } catch (e) {
                        return false;
                      }
                    }) || []
                  }
                  goals={(
                    optaEvents?.filter((item) => {
                      try {
                        return item.typeId === 16 && item?.periodId !== 5;
                      } catch (e) {
                        return false;
                      }
                    }) || []
                  )
                    .concat(game?.home_scorers)
                    .concat(game?.away_scorers)}
                />
              )}
            </Lineups>
          </ActionPanel>
          {(optaEvents?.length === 0 || selectedTeam) && (
            <ActionPanel>
              <KitSelection game={game} />
            </ActionPanel>
          )}

          {optaEvents?.length > 0 && !selectedTeam && (
            <ActionPanel>
              <Timeline
                matchEvents={optaEvents}
                liveScores={liveScores}
                game={game}
                previewGraphic={previewGraphic}
                allOptaEvents={allOptaEvents}
              />
            </ActionPanel>
          )}
          {(!optaEvents || optaEvents?.length === 0) && !selectedTeam && (
            <ActionPanel></ActionPanel>
          )}
        </Content>
      )}
    </Main>
  );
}
